import React, { useEffect } from "react"
import { ButtonLight, TextButton } from "../shared/Button/button"
import styled from "styled-components"

import Layout from "../shared/Layout/layout"
import SEO from "../components/seo"
import { ImageBlock } from "../shared/Block/block"

import DesktopImage from "../images/desktop-2.jpeg"

import PackageImage from "../images/svg/package.svg"
import WrenchImage from "../images/svg/wrench.svg"

const SectionLink = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 700;
  font-size: 1rem;

  display: inline-flex;

  transition: 0.3s all ease;

  color: var(--color-shock-2);

  border-bottom: 2px solid transparent;

  // &:hover {
  //   color: var(--color-shock-2);
  //   border-bottom: 2px solid var(--color-shock-2);
  //   cursor: pointer;
  // }
`

const TagDiv = styled.div`
  display: flex;
  flex-direction: row wrap;
  align-items: center;
  margin: 0.5rem 0;

  box-shadow: 0px 0px 4px var(--color-primary-02);
  border-radius: 0.2rem;

  transition: all ease;

  background-color: var(--color-primary-07);
  border: solid 1px transparent;
  transition-duration: 0.5s;
  color: var(--color-primary-13);
  text-shadow: 0px 0px 4px rgb(0, 0, 0, 0.4);

  &:hover {
    // background-color: hsl(0, 0%, 44%, 0.99993);
    border: solid 1px var(--color-shock-5);
    transition-duration: 0.2s;
    color: var(--color-shock-3);
    cursor: pointer;
  }
`

const ServiceListItemDiv = styled.div`
  width: 25rem;
  margin: 0rem 1.4rem 0rem 0.3rem;

  @media (max-width: calc(40rem)) {
    width: 60vw;
    line-height: 2rem;
    margin: 0 min(15px, 5vw) 0 0;
    margin: 0 3vw 0 0;
  }
`

const ServiceListItem = ({ image, children }) => (
  <TagDiv>
    <div
      style={{
        backgroundImage: `url(${image})`,
        padding: `0`,
        width: `60px`,
        height: `60px`,
        margin: `1.1rem`,
      }}
    />
    <ServiceListItemDiv>{children}</ServiceListItemDiv>
  </TagDiv>
)

const ScrollToTarget = props => {
  useEffect(() => {
    // console.assert(Boolean(props.target_id), "Missing 'target_id'")
    if (!props.target_id) {
      throw Error("Missing 'target_id'")
    }
  })

  const scroll = () => {
    document.getElementById(props.target_id).scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  return (
    <SectionLink
      target_id={props.target_id}
      onClick={scroll}
      style={{
        // fontFamily: `sans-serif`,
        alignItems: `center`,
        justifyItems: `center`,
        flexDirection: `column`,
        alignSelf: `center`,
        // backgroundColor: `red`,
        fontSize: `1.75rem`,
        // margin: `0`,
        // padding: `0`,
      }}
    >
      {props.children}
    </SectionLink>
  )
}

const ListItem = ({ children }) => (
  <div
    style={{
      padding: `1rem 1rem`,
      // backgroundColor: `var(--color-primary-04)`,
    }}
  >
    <svg
      width="0.6rem"
      height="0.6rem"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: `0.4rem` }}
    >
      <rect
        x="0"
        y="0"
        width="0.6rem"
        height="0.6rem"
        stroke="var(--color-primary-09)"
        fill="transparent"
        stroke-width="0.25rem"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>{" "}
    {children}
  </div>
)

const ServicesPage = () => (
  <>
    <Layout>
      <SEO title="Palvelut" />

      <ImageBlock
        /*image={DesktopImage}
        image_width="45rem"
        image_height="42rem"
        cover_or_contain="contain"
        background_position="right 0"
        margin_bottom="8rem"
        image_gradient="no-repeat linear-gradient(
          20deg,
          transparent 0%,
          rgb(0, 0, 0, 0.6) 95%
        )"
        background_gradient="no-repeat radial-gradient(
          ellipse at center,
          var(--color-primary-06) 10%,
          var(--color-primary-03) 85%
        )"*/

        image={DesktopImage}
        cover_or_contain="contain"
        background_position="left"
        background_gradient="no-repeat linear-gradient(
          0deg,
          var(--color-primary-02) 0%,
          var(--color-primary-04) 60%
        )"
        flex_dir="flex-end"
        preferred_width="40vw"
        preferred_max_width="74rem"
        min_height="48rem"
        min_mobile_height="36rem"

      // preferred_max_width="43rem"
      // min_height="60rem"
      // min_mobile_height="75rem"
      >
        <h1 style={{ textShadow: `0px 0px 4px rgb(0, 0, 0)` }}>Palvelut</h1>
        <div
          style={{
            fontSize: `1.10rem`,
            lineHeight: `1.5rem`,
            fontFamily: `sans-serif`,
            textShadow: `0px 0px 4px rgb(0, 0, 0)`,
            marginBottom: `var(--default-margin)`,
          }}
        >
          Team Immonen tarjoaa kahdenlaista eri palvelua:
        </div>

        <div style={{ display: `flex`, flexDirection: `column` }}>
          <ScrollToTarget target_id="technical">
            <ServiceListItem image={WrenchImage}>
              Tekninen konsultointi
            </ServiceListItem>
          </ScrollToTarget>

          <ScrollToTarget target_id="complete">
            <ServiceListItem image={PackageImage}>
              Valmis paketti
            </ServiceListItem>
          </ScrollToTarget>
        </div>
      </ImageBlock>

      <ImageBlock
        // background_gradient="no-repeat radial-gradient(circle at center, var(--color-primary-04) 10%, var(--color-primary-02) 85%)"
        background_gradient="no-repeat radial-gradient(
          circle at bottom,
          var(--color-primary-05),
          var(--color-primary-02)
        )"
        preferred_max_width="43rem"
        min_height="60rem"
        min_mobile_height="75rem"
      /*background_gradient="
          no-repeat radial-gradient(
            ellipse at center,
            var(--color-primary-05),
            var(--color-primary-02)
          )
        "*/
      >
        <h2 id="technical">Tekninen konsultointi</h2>
        <p>
          Haluatko itse oppia tekemään ja ylläpitämään sivuja, etkä pelkää
          tietotekniikkaa? Tarjoan apua sivuston pystytyksen kanssa sekä näytän
          kuinka voit muuttaa sivujesi sisältöä ja ulkoasua.
        </p>
        <p>
          Teknisten haasteiden kanssa voit kääntyä Team Immosen puoleen, jolloin
          ratkon puolestasi mm. seuraavanlaisia haasteita:
          <div
            style={{
              margin: `3rem 0`,
              display: `grid`,
              gap: `0.5rem`,
              gridTemplateColumns: `repeat(auto-fill, minmax(15rem, 1fr))`,
            }}
          >
            <ListItem>varmuuskopiointi</ListItem>
            <ListItem>teknisten asetusten konfigurointi</ListItem>
            <ListItem>virheiden korjaus</ListItem>
            <ListItem>sivuston komponenttien asennus</ListItem>
            <ListItem>automaattiset päivitykset</ListItem>
          </div>
          <br />
          ...ja niin edelleen. Lähetä viestiä ja kysy lisäinfoa:
        </p>
        <p>
          <TextButton to="/contact" key="Contact me">
            <h3
              style={{
                textShadow: `0px 0px 4px rgb(0, 0, 0)`,
              }}
            >
              Ota yhteyttä {"→"}
            </h3>
          </TextButton>
        </p>
      </ImageBlock>

      <ImageBlock
        background_gradient="no-repeat radial-gradient(
          circle at bottom,
          var(--color-primary-04) 10%,
          var(--color-primary-02) 85%)`
        "
        min_height="40rem"
        min_mobile_height="40rem"
      >
        <h2 id="complete">Valmis paketti</h2>
        <p>
          Haluatko toimivat sivut ilman, että joudut kirjoittamaan riviäkään
          koodia?
        </p>
        <p>
          Jos haluat sivut ilman että joudut ohjelmoimaan tai ratkomaan teknisiä
          ongelmia - valmis paketti on sinua varten. Luon toiveittesi mukaiset
          sivut, vapauttaen sinut keskittymään sivujesi sisältöön.
        </p>
        <p>
          <ButtonLight to="/pricing" key="Pricing">
            Hinnasto
          </ButtonLight>
        </p>
      </ImageBlock>
    </Layout>
  </>
)

export default ServicesPage
